*
    margin: 0
    padding: 0

body
  margin: 0
  background: #151616
  font-family: roboto
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
:root 
  --speed: 400ms

.overlay 
    position: fixed
    width: 100%
    height: 100%
    top: 0
    left: 0
    z-index: 2
    background-color: rgba(0,0,0,0.4)
    transition: 0.3s linear
.main_wrapper
    min-height: 91vh
    position: relative
a
    text-decoration: none

select
    -webkit-appearance: none
    -moz-appearance: none
    -ms-appearance: none
    appearance: none
    outline: 0
    box-shadow: none
    border: 0!important

.header
    width: 100% 
    position: fixed 
    z-index: 2 
    background-color: #fff
    padding-top: 20px
    &_img
        width: 30%
        margin-bottom: 3%
    .wrapper
        display: flex
        justify-content: space-between	
        align-items: baseline
    .wrapper > div
        width: 33%
        &:nth-child(3)
            display: flex
            justify-content: flex-end
    &_icons
        display: flex
        flex-direction: row
        &_basket
            display: flex
            align-items: baseline
            span
                color: $text_color
            img
                width: 22px
            svg
                height: 24px
                width: 30px
                color: $accent_color
    &_menu
        list-style: none
        justify-content: center
        &_link
            padding: 0 40px
            img
                margin-left: 2px
            &:hover
                font-weight: 500
        &_item
            color: $text_color
            font-size: 20px
            font-weight: 300
            &:hover
                color: $accent_color
            a
                color: $text_color
                &:hover
                    color: $accent_color
    &_dropdown
        display: flex
        &_content
            position: absolute
            top: 77%
            background: $dropdown_1
            border-radius: 4px
            padding: 5px
            animation: dropdown-open 0.6s forwards
            @keyframes dropdown-open
                0%
                    opacity: 0
                100%
                    opacity: 100%
        &_btn
            padding: 2px 0px 
            display: flex
        &_item
            display: block
            text-transform: capitalize
            color: $white_color
            padding: 5px 10px
            transition: all 0.2s
            font-size: 16px
            &:hover
                color: $white_color
                background-color: $dropdown_2
                border-radius: 4px
            a
                color: $white_color
                &:hover
                    color: $white_color


    ul
        display: flex
    &_icons
        a
            padding: 0 12px
.explore
    &_container
        text-align: center
    &_img
        width: 100%
        padding-top: 100px
        &_mobile
            display: none
    &_descr
        margin-top: 30px
        color: $text_color
        font-size: 18px
        font-weight: 300
        line-height: 25px
        letter-spacing: 0.02em
    &_btn
        width: 260px
        height: 69px
        margin-top: 30px
        margin-bottom: 30px
        +btn
        +btn_hover

footer
    background-color: $bg_color
    padding: 20px 0 8px 0
    bottom: 0
    width: 100%
    .footer
        &_wrapper
            display: flex
            justify-content: space-between	
            align-items: center
        &_wrapper > div
            width: 33%
            &:nth-child(3)
                display: flex
                justify-content: flex-end
                text-align: end
            &:nth-child(2)
                text-align: center
                
        &_logo
            img
                width: 135px
        &_rights
            +footer_text
        &_details
            &_hours
                +footer_text
            &_mobile
                font-size: 18px
                font-weight: 400
                line-height: 25px
                color: $title_color
.shop
    background-color: $categories_bg
    padding-bottom: 50px    
    &_title
        font-size: 40px
        font-weight: 300
        letter-spacing: 0.05em
        text-transform: capitalize
        padding-top: 80px
        color: $title_color
    &_categories
        padding-top: 40px
        &_item
            background-color: $white_color
            padding: 10%
            margin-bottom: 30px
            &_title
                font-size: 22px
                font-weight: 300
                letter-spacing: 0.03em
                text-align: center
                color: $title_color
                text-transform: capitalize
            &_img
                background-color: $bg_color
                height: 100%
                text-align: center
                img
                    max-width: 100%
                    max-height: 100%
                    vertical-align: middle

.contact
    margin: 110px 0
    &_img
        border: 1px solid $border_color
        min-height: 360px
        img
            max-height: 320px
            display: block
            margin: auto
            margin-top: 20px
    &_descr
        font-weight: 300
        letter-spacing: 0.01em
        margin-left: 129px
        &_title
            font-size: 38px
            color: $title_color
            line-height: 38px
        &_text
            font-size: 18px
            color: $text_color
            max-width: 297px
            line-height: 25px
            margin-top: 34px
    &_form
        margin-left: 150px
        form
            display: flex
            flex-direction: column
            max-width: 320px
            input, textarea
                font-family: "Atlas Grotesk"
                min-height: 69px
                margin-bottom: 15px
                border: 1px solid $border_color       
                font-size: 18px
                font-weight: 400
                color: $input_color
                padding-left: 22px  
                outline: none
                &::placeholder
                    padding-top: 22px
                &:focus-visible
                    border: 1px solid $text_color
                    &::placeholder
                        color: transparent
                &:nth-child(3)
                    min-height: 108px
                &:last-child
                    margin-bottom: 0
                    padding: 0
                    +btn
                    +btn_hover
        &_message
            padding-top: 20px
        &_success
            color: $white_color
            width: 320px
            font-size: 23px
            margin-top: 20px
            background: #5abc5a
            padding: 5px 0px
            text-align: center
            line-height: 28px
            border-radius: 5px
            margin-bottom: 20px
        &_noSuccess
            display: none
.slider
    height: 100%
    position: relative
    &_slide
        width: 100%
        height: 100%
        background-position: center
        background-size: cover
    &_arrow
        &_left
            position: absolute
            top:50%
            transform: translate(0, -50%)
            font-size: 45px
            color: #f2ecef
            cursor: pointer
            z-index: 1
            left: -7px
        &_right
            position: absolute
            top:50%
            transform: translate(0, -50%)
            font-size: 45px
            color: #f2ecef
            cursor: pointer
            z-index: 1
            right: -7px
    &_dots
        display: flex
        justify-content: center
        margin-bottom: 20px
    &_dot
        margin: 10px 3px
        cursor: pointer
        font-size: 20px
        color: $text_color

.product
    padding-top: 115px
    &_nav
        font-size: 18px
        font-weight: 300
        letter-spacing: 0.01em
        a
            color: $text_color
            text-transform: capitalize
            &:hover
                color: $accent_color
    &_slider
        max-height: 60vh
    &_img
        // width: 100%
        height: 100%
        aspect-ratio: 2/2.5
        margin-top: 30px
    &_info
        margin-top: 30px
        &_title
            font-size: 20px
            font-weight: 300
            letter-spacing: 0.12em
            text-transform: capitalize
            color: $title_color
        &_price
            margin-top: 20px
            font-size: 16px
            font-weight: 400
            color: $text_color
        &_descr
            margin-top: 60px
            font-size: 18px
            font-weight: 300
            letter-spacing: 0.01em
            max-width: 282px    
            color: $text_color
        &_btn
            margin: 50px 0 60px 0
            width: 200px
            height: 50px
            +btn
            +btn_hover
            &_qty                    
                display: flex
                justify-content: space-between
                margin: 50px 0 60px 0
                width: 200px
                height: 50px
                button
                    width: 33%
                    
                    +btn
                    +btn_hover
                    &:nth-child(2)
                        color: $title_color
                        background-image: none
                        background-color: $white_color
        &_divider
            height: 1px
            max-width: 384px
            background-color: $input_color
            margin-bottom: 18px
        &_arrow
            background: none
            border: none
            transition: 0.3s all
            +btn_focus
            &_active
                background: none
                border: none
                transform: translateY(-3px) rotate(180deg)
                transition: 0.3s all
                +btn_focus
                svg
                    color: $accent_color
            svg
                color: $accent_color
        &_title
            font-size: 13px
            font-weight: 300
            letter-spacing: 0.04em
            text-transform: uppercase
        &_wrapper
            display: flex
            justify-content: space-between
            max-width: 384px
        &_specsAndShipping
            margin-bottom: 20px
            color: $text_color
            ul 
                margin-top: 11px
                li
                    font-size: 11px
                    font-weight: 300
                    letter-spacing: 0.01em
                    text-transform: lowercase
                    line-height: 15px
.products
    padding-top: 12vh
    background-color: $categories_bg
    min-height: 91vh
    &_container
        margin-top: 50px
    &_alert
        color: $text_color
        margin: 0 auto
        text-align: center
    
.thumbnail
    // box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.03)
    height: 100%
    &_img
        aspect-ratio: 1/1
        margin: 6%
        border-radius: 4px
    &_descr
        span
            font-size: 100%
            text-transform: capitalize
            font-weight: 400    
            letter-spacing: 0.03em
            padding-left: 6%
.basket
    justify-content: space-evenly
    &_container
        padding-top: 140px
    &_item     
        display: flex
        justify-content: space-between
        border-radius: 5px
        margin-bottom: 40px
        padding: 10px
        box-shadow: 0 4px 20px rgba(34,34,34,.15)
        max-width: 450px
        &_info
            &_title
                text-transform: capitalize
            &_qty
                display: flex
                justify-content: space-between
                margin-top: 20px
                &_count
                    width: 50%
                    text-align: center
                &_btn
                    display: flex
                    justify-content: space-between
                    width: 50%
                    button
                        width: 40%
                        border: none
                        border-radius: 4px
                        outline: none
                        
        &_img
            margin-right: 10px
            img
                border-radius: 5px
                width: 100px
                height: 100px
        &_remove
            button
                border: none
                background: white
                outline: none
    &_payment
        &_header
            // display: none
            margin-bottom: 40px
            text-transform: uppercase
            font-family: atlas grotesk
            font-size: 13px
            font-weight: 500
    &_subtotal
        text-transform: uppercase
        margin-bottom: 20px
        font-family: atlas grotesk
        font-size: 13px
        &_text
            font-weight: 500
        &_price
            font-weight: 200
        &_empty
            text-transform: uppercase
            margin-bottom: 20px
            font-family: atlas grotesk
            font-size: 13px
            font-weight: 500
            text-align: center
.filter_and_sort
    display: flex
    justify-content: space-between
    &_header
        text-align: center
        font-size: 40px
        font-weight: 300
        letter-spacing: 0.05em
        text-transform: capitalize
        color: $title_color 
        font-family: atlas grotesk
        padding-bottom: 20px
        padding-top: 20px
.filter_and_sort_mobile
    display: none
    width: 100vw
    position: relative
    max-width: 100vw
    left: 50%
    margin-left: -50vw
    justify-content: space-evenly
    background: $background_1
    color: $white_color
    padding: 10px
    letter-spacing: 0.04em
    &_filter
        text-transform: uppercase
        display: flex
        flex-direction: row
        div
            display: flex
            svg
                height: 20px

.filter
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    color: $title_color
    &_by
        &_type
            display: flex
            align-items: baseline
        &_gemstone
            display: flex
            align-items: baseline
            h5
                margin-left: 20px
    h5
        font-weight: 500
        margin-right: 20px
        font-size: 16px
        letter-spacing: 0.02em

.dropdown
    width: 200px
    user-select: none
    position: relative
    &_btn
        padding: 15px 20px  
        background: #fff
        box-shadow: 3px 3px 5px 3px rgba(0,0,0,0.03)
        font-weight: bold
        color: $text_color
        display: flex
        align-items: center
        justify-content: space-between
        border-radius: 4px
        text-transform: capitalize
        letter-spacing: 0.02em
    &_content
        position: absolute
        top: 110%
        left: 0
        width: 100%
        padding: 10px
        background: $background_1
        box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.06)
        font-weight: 500
        color: $white_color
        z-index: 1
        border-radius: 4px
    &_item
        padding: 10px
        transition: all 0.2s
        text-transform: capitalize
        letter-spacing: 0.08em
        &:hover
            background: $background_1
            border-radius: 4px
    &_mobile
        text-transform: uppercase
        &_sort
            text-transform: uppercase
            display: flex
            flex-direction: row
            div
                display: flex
                align-items: center
                svg
                    height: 20px
                    stroke-width: 0.4px

/* Sidebar filter Menu
.filteringMenu
    height: 100%
    width: calc(100% - 50px)
    position: fixed
    z-index: 10
    right: 0
    top: 0
    animation: slide-open 0.3s forwards
    @keyframes slide-open
            0%
                right: -100%
            100%
                right: 0
    &_sidebar
        position: absolute
        top: 0
        height: 100%
        left: 100%
        width: 100%
        outline-width: 0
        background-color: $bg_color
        z-index: 10
        transform: translateX(-100%)
        overflow: hidden
        // transition: height var(--speed) ease
    &_header
        color: $title_color
        text-transform: uppercase
        padding-top: 8px
        margin-bottom: 10px
        letter-spacing: 2px
        font-weight: bold
        background-color: $white_color
        display: flex
        align-items: baseline
    &_button
        color: $title_color
        width: calc( 100% - 40px )
        padding: 9px 10px
        margin: 16px auto
        background-image: linear-gradient(to top, $button_color_1 0%, $button_color_2 100%)
        font-weight: normal
        color: $white_color
        border: none  
        outline: none
        letter-spacing: 1px
        text-transform: uppercase
        font-size: 13px
        &_wrapper
            width: 100%
            height: 70px
            position: absolute
            bottom: 0
            background-color: $white_color
            text-align: center
            overflow: hidden
    &_reset
        position: absolute
        right: 10px
        // margin-top: 20px
        color: $text_color
        text-transform: uppercase
        font-weight: 200

.menu
    width: 100%
    z-index: 100
    height: 100vh
    &_item
        height: 50px
        display: flex
        align-items: center
        transition: background var(--speed)
        padding: 0.5rem
        background: $white_color
        border-bottom: 1px solid #EEDFE7
        color: $text_color
        &:hover
            color: $accent_color
    &_subitem
        padding-left: 20px
        text-transform: capitalize
        &_checked
            color: $title_color
            font-weight: 900
            display: flex
            justify-content: space-between
            span
                margin-right: 10px

/* Sidebar filter Menu CSSTransition classes

.menu-primary-enter
    position: absolute
    transform: translateX(-110%)

.menu-primary-enter-active
    transform: translateX(0%)
    transition: all var(--speed) ease

.menu-primary-exit
    position: absolute

.menu-primary-exit-active
    transform: translateX(-110%)
    transition: all var(--speed) ease

.menu-secondary-enter
    transform: translateX(110%)

.menu-secondary-enter-active
    transform: translateX(0%)
    transition: all var(--speed) ease

.menu-secondary-exit-active
    transform: translateX(110%)ia
    transition: all var(--speed) ease
    
.admin
    &_form
        padding-top: 110px
        h3
            text-align: center
            color: $text_color
            text-transform: capitalize
        h4
            color: $text_color
            text-transform: capitalize
            padding-left: 10px
    &_products
        h3
            text-align: center
            color: $text_color
            text-transform: capitalize
            padding-top: 30px
        thead
            tr
                th  
                    font-weight: bold
                    padding: 10px
        tbody
            tr 
                td
                    padding: 10px
    &_mainInfoWrap
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        border-bottom: 1px dotted grey
        padding-bottom: 20px
    &_specsWrap
        display: flex
        border-bottom: 1px dotted grey
        padding-bottom: 20px
        flex-wrap: wrap
    &_photosWrap
        border-top: 1px dotted grey
        padding: 20px 0 20px 0
        h4
            margin-bottom: 0
    &_photos
        display: flex
        flex-wrap: wrap
    &_photo
        display: flex
        flex-direction: column
        margin: 0 10px 0 8px
        img
            margin-bottom: 10px
        &_upload
            display: flex
            flex-wrap: wrap
            button
                margin-top: 8px
    &_specs
        display: flex
        flex-wrap: wrap
    &_spec
        display: flex
        margin-right: 10px
        align-items: center

@media (max-width: $breakpoint_lg) // <992px
    .basket
        justify-content: start
@media (max-width: $breakpoint_md) // <768px
    .header
        &_menu
            &_link
                padding: 0 20px
            &_item
                font-size: 14px
        &_icons
            &_basket
                img
                    width: 16px
                svg
                    height: 15px
                    width: 20px
    .explore
        min-height: 0
        margin-bottom: 30px
        &_row
            flex-direction: column
        &_descr
            margin-top: 30px
            display: block
            margin: 0 auto
            text-align: center
            margin-bottom: 1vh
            margin-top: 1vh
            font-size: 15px
        &_btn
            display: block
            margin: 0 auto
            width: 200px
            height: 50px
        &_img
            display: none
            &_mobile
                display: block
                width: 100%
                padding-top: 60px
    .filter_and_sort
        display: none
        &_header
            font-size: 28px
            padding-bottom: 0
    .filter_and_sort_mobile
        display: flex
    .slider
        &_arrow
            &_left
                left: 0px
            &_right
                right: 0px         

    .shop
        padding-bottom: 30px
        &_title
            font-size: 30px
            text-align: center
            padding-top: 30px
        &_categories
            padding-top: 30px
    .contact
        margin: 30px 0 0 0
        &_wrapper
            display: flex
            flex-direction: column
        &_img
            display: none
        &_descr
            margin: 0 auto
            text-align: center
            &_title
                font-size: 25px
            &_text
                max-width:100%
                margin-top: 4px
                font-size: 14px
        &_form
            margin-left: 0
            form
                margin-top: 20px
                max-width: 100%
                margin-bottom: 20px
                input
                    min-height: 50px
                    font-size: 16px
                    &:nth-child(3)
                        height: 50px
                    &:last-child
                        min-height: 0
                        display: block
                        margin: 0 auto
                        width: 200px
                        height: 50px
            &_success
                display: block
                margin: 0 auto
                font-size: 20px
                margin-bottom: 20px
    .products
        padding-top: 6vh
        &_container
            margin-top: 30px
    .product
        padding-top: 65px
        &_nav
            font-size: 16px
        &_img
            margin: 0 auto
            margin-top: 30px
        &_info
            margin-top: 80px
            text-align: center
            &_divider
                max-width: 100%
            &_wrapper
                max-width: 100%
            &_specsAndShipping
                ul
                    margin-left: 15px
                    text-align: left
            &_descr
                margin-top: 16px
                font-size: 15px
                max-width: 100%
            &_btn
                display: block
                margin: 0 auto
                width: 200px
                height: 50px
                margin-top: 20px
                margin-bottom: 20px
                &_qty
                    display: block
                    margin: 0 auto
                    margin-top: 20px
                    width: 200px
                    margin-bottom: 20px
                    button
                        height: 50px
                        

    .footer
        &_wrapper
            div
                font-size: 10px
    footer
        .footer
            &_logo
                img 
                    width: 70px          
    .basket
        justify-content: start
        &_container
            padding-top: 90px
        &_payment
            &_header
                margin-bottom: 20px
        &_item
            // max-width: 100%
            margin-bottom: 20px
    .dropdown
        width: 80px
        &_content
            top: 104%
            left: 0
            font-size: 10px
            padding: 3px
            width: calc( 50vw - 10px )
            text-align: center
            animation: dropdown-open 0.6s forwards
            @keyframes dropdown-open
                0%
                    opacity: 0
                100%
                    opacity: 100%
        &_item
            text-transform: capitalize
            padding: 2px 8px
            &_selected
                background-color: $background_1
                text-transform: capitalize
                border-radius: 4px
                margin: 3px
                padding: 2px

@media (max-width: $breakpoint_sm) // <576px
// @media (max-width: $breakpoint_tablet)
//     .header
//         &_menu
//             &_link
//                 padding: 0 20px
//             &_item
//                 font-size: 14px
//         &_icons
//             &_basket
//                 img
//                     max-width: 16px
//                 span
//                     font-size: 12px
//         &_logo
//             a
//                 img
//                     width: 5rem
//         &_dropdown
//             &_item
//                 font-size: 12px
//                 padding: 2px 3px
//     .products
//         padding-top: 7vh
//         &_container
//             margin-top: 30px
//     .filter_and_sort
//         display: none
//     .filter_and_sort_mobile
//         display: flex
//     .slider
//         &_arrow
//             &_left
//                 left: 0px
//             &_right
//                 right: 0px
//     .product
//         padding-top: 70px
//         &_img
//             max-height: 440px
//             margin: 0 auto
//             margin-top: 20px
//             margin-bottom: 30px
//         &_nav
//             font-size: 14px
//         &_info
//             margin-top: 20px
//             &_divider
//                 width: 100%
//             &_descr
//                 margin-top: 20px
//                 font-size: 14px
//             &_specs
//                 margin-left: 14px
//                 &_item
//                     animation: dropdown-open 0.6s forwards
//                     @keyframes dropdown-open
//                         0%
//                             opacity: 0
//                         100%
//                             opacity: 100%
//             &_btn
//                 margin: 10px 0 20px 0
//                 width: 100%
//                 height: 40px
//                 &_qty
//                     margin: 10px 0 20px 0
//                     width: 100%
//                     height: 40px
//     .footer
//         &_wrapper
//             div
//                 font-size: 10px
//     footer
//         .footer
//             &_logo
//                 img 
//                     width: 100px
//     .dropdown
//         width: 80px
//         &_btn
//             padding: 4px 6px
//             font-size: 10px
//             font-weight: normal
//             &_sort
//                 width: 104px
//         &_content
//             top: 104%
//             left: 0
//             font-size: 10px
//             padding: 3px
//             width: calc( 50vw - 10px )
//             text-align: center
//             animation: dropdown-open 0.6s forwards
//             @keyframes dropdown-open
//                 0%
//                     opacity: 0
//                 100%
//                     opacity: 100%
//         &_item
//             text-transform: capitalize
//             padding: 2px 8px
//             &_selected
//                 background-color: $background_1
//                 text-transform: capitalize
//                 border-radius: 4px
//                 margin: 3px
//                 padding: 2px
        
//     .filter
//         margin-top: 20px
//         h5
//             font-size: 10px
//             // margin: 0
//         &_by_type
//             h5
//                 margin: 0
//         //     flex-direction: column
//         &_by_gemstone
//             h5
//                 margin: 0
        //     flex-direction: column       
// @media (max-width: $breakpoint_tv) and (min-width: $breakpoint_desktop)

//     .explore
//         min-height: 800px
//         &_img
//             &_1
//                 margin-top: 113px
//             &_2
//                 right: 0
//                 top: 450px
//                 max-width: 250px
//     .product
//         padding-top: 110px
//         &_info
//             margin-top: 27px
//             &_btn
//                 margin-bottom: 50px
  
                        

           
