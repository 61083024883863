@font-face {
  font-family: "Atlas Grotesk";
  font-weight: 100;
  src: local("AtlasGrotesk-thin"), url("../fonts/ATLASGROTESK-THIN.OTF") format("otf");
}
@font-face {
  font-family: "Atlas Grotesk";
  font-weight: 300;
  src: local("AtlasGrotesk-light"), url("../fonts/ATLASGROTESK-LIGHT.OTF") format("otf");
}
@font-face {
  font-family: "Atlas Grotesk";
  font-weight: 400;
  src: local("AtlasGrotesk-regular"), url("../fonts/ATLASGROTESK-REGULAR.OTF") format("otf");
}
@font-face {
  font-family: "Atlas Grotesk";
  font-weight: 500;
  src: local("AtlasGrotesk-medium"), url("../fonts/ATLASGROTESK-MEDIUM.OTF") format("otf");
}
@font-face {
  font-family: "Atlas Grotesk";
  font-weight: 700;
  src: local("AtlasGrotesk-black"), url("../fonts/ATLASGROTESK-BLACK.OTF") format("otf");
}
* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  background: #151616;
  font-family: roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --speed: 400ms;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear;
}

.main_wrapper {
  min-height: 91vh;
  position: relative;
}

a {
  text-decoration: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 0 !important;
}

.header {
  width: 100%;
  position: fixed;
  z-index: 2;
  background-color: #fff;
  padding-top: 20px;
}
.header_img {
  width: 30%;
  margin-bottom: 3%;
}
.header .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.header .wrapper > div {
  width: 33%;
}
.header .wrapper > div:nth-child(3) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.header_icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.header_icons_basket {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.header_icons_basket span {
  color: grey;
}
.header_icons_basket img {
  width: 22px;
}
.header_icons_basket svg {
  height: 24px;
  width: 30px;
  color: #734860;
}
.header_menu {
  list-style: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.header_menu_link {
  padding: 0 40px;
}
.header_menu_link img {
  margin-left: 2px;
}
.header_menu_link:hover {
  font-weight: 500;
}
.header_menu_item {
  color: grey;
  font-size: 20px;
  font-weight: 300;
}
.header_menu_item:hover {
  color: #734860;
}
.header_menu_item a {
  color: grey;
}
.header_menu_item a:hover {
  color: #734860;
}
.header_dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header_dropdown_content {
  position: absolute;
  top: 77%;
  background: rgba(115, 72, 96, 0.7);
  border-radius: 4px;
  padding: 5px;
  -webkit-animation: dropdown-open 0.6s forwards;
          animation: dropdown-open 0.6s forwards;
}
@-webkit-keyframes dropdown-open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
@keyframes dropdown-open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
.header_dropdown_btn {
  padding: 2px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header_dropdown_item {
  display: block;
  text-transform: capitalize;
  color: #ffffff;
  padding: 5px 10px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 16px;
}
.header_dropdown_item:hover {
  color: #ffffff;
  background-color: rgba(115, 72, 96, 0.8);
  border-radius: 4px;
}
.header_dropdown_item a {
  color: #ffffff;
}
.header_dropdown_item a:hover {
  color: #ffffff;
}
.header ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header_icons a {
  padding: 0 12px;
}

.explore_container {
  text-align: center;
}
.explore_img {
  width: 100%;
  padding-top: 100px;
}
.explore_img_mobile {
  display: none;
}
.explore_descr {
  margin-top: 30px;
  color: grey;
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0.02em;
}
.explore_btn {
  width: 260px;
  height: 69px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #ffffff;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#734860), to(#dac6ce));
  background-image: linear-gradient(to top, #734860 0%, #dac6ce 100%);
  border: none;
  border-radius: 4px;
  outline: none;
}
.explore_btn:focus {
  outline: none;
}
.explore_btn:hover {
  background-image: none;
  background-color: #dac6ce;
}

footer {
  background-color: #f2ecef;
  padding: 20px 0 8px 0;
  bottom: 0;
  width: 100%;
}
footer .footer_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
footer .footer_wrapper > div {
  width: 33%;
}
footer .footer_wrapper > div:nth-child(3) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  text-align: end;
}
footer .footer_wrapper > div:nth-child(2) {
  text-align: center;
}
footer .footer_logo img {
  width: 135px;
}
footer .footer_rights {
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: grey;
}
footer .footer_details_hours {
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: grey;
}
footer .footer_details_mobile {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: #535353;
}

.shop {
  background-color: #f2ecef;
  padding-bottom: 50px;
}
.shop_title {
  font-size: 40px;
  font-weight: 300;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  padding-top: 80px;
  color: #535353;
}
.shop_categories {
  padding-top: 40px;
}
.shop_categories_item {
  background-color: #ffffff;
  padding: 10%;
  margin-bottom: 30px;
}
.shop_categories_item_title {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.03em;
  text-align: center;
  color: #535353;
  text-transform: capitalize;
}
.shop_categories_item_img {
  background-color: #f2ecef;
  height: 100%;
  text-align: center;
}
.shop_categories_item_img img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.contact {
  margin: 110px 0;
}
.contact_img {
  border: 1px solid #DABCC6;
  min-height: 360px;
}
.contact_img img {
  max-height: 320px;
  display: block;
  margin: auto;
  margin-top: 20px;
}
.contact_descr {
  font-weight: 300;
  letter-spacing: 0.01em;
  margin-left: 129px;
}
.contact_descr_title {
  font-size: 38px;
  color: #535353;
  line-height: 38px;
}
.contact_descr_text {
  font-size: 18px;
  color: grey;
  max-width: 297px;
  line-height: 25px;
  margin-top: 34px;
}
.contact_form {
  margin-left: 150px;
}
.contact_form form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 320px;
}
.contact_form form input, .contact_form form textarea {
  font-family: "Atlas Grotesk";
  min-height: 69px;
  margin-bottom: 15px;
  border: 1px solid #DABCC6;
  font-size: 18px;
  font-weight: 400;
  color: #9D9D9D;
  padding-left: 22px;
  outline: none;
}
.contact_form form input::-webkit-input-placeholder, .contact_form form textarea::-webkit-input-placeholder {
  padding-top: 22px;
}
.contact_form form input::-moz-placeholder, .contact_form form textarea::-moz-placeholder {
  padding-top: 22px;
}
.contact_form form input:-ms-input-placeholder, .contact_form form textarea:-ms-input-placeholder {
  padding-top: 22px;
}
.contact_form form input::-ms-input-placeholder, .contact_form form textarea::-ms-input-placeholder {
  padding-top: 22px;
}
.contact_form form input::placeholder, .contact_form form textarea::placeholder {
  padding-top: 22px;
}
.contact_form form input:focus-visible, .contact_form form textarea:focus-visible {
  border: 1px solid grey;
}
.contact_form form input:focus-visible::-webkit-input-placeholder, .contact_form form textarea:focus-visible::-webkit-input-placeholder {
  color: transparent;
}
.contact_form form input:focus-visible::-moz-placeholder, .contact_form form textarea:focus-visible::-moz-placeholder {
  color: transparent;
}
.contact_form form input:focus-visible:-ms-input-placeholder, .contact_form form textarea:focus-visible:-ms-input-placeholder {
  color: transparent;
}
.contact_form form input:focus-visible::-ms-input-placeholder, .contact_form form textarea:focus-visible::-ms-input-placeholder {
  color: transparent;
}
.contact_form form input:focus-visible::placeholder, .contact_form form textarea:focus-visible::placeholder {
  color: transparent;
}
.contact_form form input:nth-child(3), .contact_form form textarea:nth-child(3) {
  min-height: 108px;
}
.contact_form form input:last-child, .contact_form form textarea:last-child {
  margin-bottom: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #ffffff;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#734860), to(#dac6ce));
  background-image: linear-gradient(to top, #734860 0%, #dac6ce 100%);
  border: none;
  border-radius: 4px;
  outline: none;
}
.contact_form form input:last-child:focus, .contact_form form textarea:last-child:focus {
  outline: none;
}
.contact_form form input:last-child:hover, .contact_form form textarea:last-child:hover {
  background-image: none;
  background-color: #dac6ce;
}
.contact_form_message {
  padding-top: 20px;
}
.contact_form_success {
  color: #ffffff;
  width: 320px;
  font-size: 23px;
  margin-top: 20px;
  background: #5abc5a;
  padding: 5px 0px;
  text-align: center;
  line-height: 28px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.contact_form_noSuccess {
  display: none;
}

.slider {
  height: 100%;
  position: relative;
}
.slider_slide {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
.slider_arrow_left {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  font-size: 45px;
  color: #f2ecef;
  cursor: pointer;
  z-index: 1;
  left: -7px;
}
.slider_arrow_right {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  font-size: 45px;
  color: #f2ecef;
  cursor: pointer;
  z-index: 1;
  right: -7px;
}
.slider_dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 20px;
}
.slider_dot {
  margin: 10px 3px;
  cursor: pointer;
  font-size: 20px;
  color: grey;
}

.product {
  padding-top: 115px;
}
.product_nav {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.01em;
}
.product_nav a {
  color: grey;
  text-transform: capitalize;
}
.product_nav a:hover {
  color: #734860;
}
.product_slider {
  max-height: 60vh;
}
.product_img {
  height: 100%;
  aspect-ratio: 2/2.5;
  margin-top: 30px;
}
.product_info {
  margin-top: 30px;
}
.product_info_title {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.12em;
  text-transform: capitalize;
  color: #535353;
}
.product_info_price {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  color: grey;
}
.product_info_descr {
  margin-top: 60px;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.01em;
  max-width: 282px;
  color: grey;
}
.product_info_btn {
  margin: 50px 0 60px 0;
  width: 200px;
  height: 50px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #ffffff;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#734860), to(#dac6ce));
  background-image: linear-gradient(to top, #734860 0%, #dac6ce 100%);
  border: none;
  border-radius: 4px;
  outline: none;
}
.product_info_btn:focus {
  outline: none;
}
.product_info_btn:hover {
  background-image: none;
  background-color: #dac6ce;
}
.product_info_btn_qty {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 50px 0 60px 0;
  width: 200px;
  height: 50px;
}
.product_info_btn_qty button {
  width: 33%;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #ffffff;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#734860), to(#dac6ce));
  background-image: linear-gradient(to top, #734860 0%, #dac6ce 100%);
  border: none;
  border-radius: 4px;
  outline: none;
}
.product_info_btn_qty button:focus {
  outline: none;
}
.product_info_btn_qty button:hover {
  background-image: none;
  background-color: #dac6ce;
}
.product_info_btn_qty button:nth-child(2) {
  color: #535353;
  background-image: none;
  background-color: #ffffff;
}
.product_info_divider {
  height: 1px;
  max-width: 384px;
  background-color: #9D9D9D;
  margin-bottom: 18px;
}
.product_info_arrow {
  background: none;
  border: none;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}
.product_info_arrow:focus {
  outline: none;
}
.product_info_arrow_active {
  background: none;
  border: none;
  -webkit-transform: translateY(-3px) rotate(180deg);
          transform: translateY(-3px) rotate(180deg);
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}
.product_info_arrow_active:focus {
  outline: none;
}
.product_info_arrow_active svg {
  color: #734860;
}
.product_info_arrow svg {
  color: #734860;
}
.product_info_title {
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
.product_info_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 384px;
}
.product_info_specsAndShipping {
  margin-bottom: 20px;
  color: grey;
}
.product_info_specsAndShipping ul {
  margin-top: 11px;
}
.product_info_specsAndShipping ul li {
  font-size: 11px;
  font-weight: 300;
  letter-spacing: 0.01em;
  text-transform: lowercase;
  line-height: 15px;
}

.products {
  padding-top: 12vh;
  background-color: #f2ecef;
  min-height: 91vh;
}
.products_container {
  margin-top: 50px;
}
.products_alert {
  color: grey;
  margin: 0 auto;
  text-align: center;
}

.thumbnail {
  height: 100%;
}
.thumbnail_img {
  aspect-ratio: 1/1;
  margin: 6%;
  border-radius: 4px;
}
.thumbnail_descr span {
  font-size: 100%;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.03em;
  padding-left: 6%;
}

.basket {
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
.basket_container {
  padding-top: 140px;
}
.basket_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 40px;
  padding: 10px;
  -webkit-box-shadow: 0 4px 20px rgba(34, 34, 34, 0.15);
          box-shadow: 0 4px 20px rgba(34, 34, 34, 0.15);
  max-width: 450px;
}
.basket_item_info_title {
  text-transform: capitalize;
}
.basket_item_info_qty {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 20px;
}
.basket_item_info_qty_count {
  width: 50%;
  text-align: center;
}
.basket_item_info_qty_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 50%;
}
.basket_item_info_qty_btn button {
  width: 40%;
  border: none;
  border-radius: 4px;
  outline: none;
}
.basket_item_img {
  margin-right: 10px;
}
.basket_item_img img {
  border-radius: 5px;
  width: 100px;
  height: 100px;
}
.basket_item_remove button {
  border: none;
  background: white;
  outline: none;
}
.basket_payment_header {
  margin-bottom: 40px;
  text-transform: uppercase;
  font-family: atlas grotesk;
  font-size: 13px;
  font-weight: 500;
}
.basket_subtotal {
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: atlas grotesk;
  font-size: 13px;
}
.basket_subtotal_text {
  font-weight: 500;
}
.basket_subtotal_price {
  font-weight: 200;
}
.basket_subtotal_empty {
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: atlas grotesk;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}

.filter_and_sort {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.filter_and_sort_header {
  text-align: center;
  font-size: 40px;
  font-weight: 300;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #535353;
  font-family: atlas grotesk;
  padding-bottom: 20px;
  padding-top: 20px;
}

.filter_and_sort_mobile {
  display: none;
  width: 100vw;
  position: relative;
  max-width: 100vw;
  left: 50%;
  margin-left: -50vw;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  background: rgba(115, 72, 96, 0.8);
  color: #ffffff;
  padding: 10px;
  letter-spacing: 0.04em;
}
.filter_and_sort_mobile_filter {
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.filter_and_sort_mobile_filter div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.filter_and_sort_mobile_filter div svg {
  height: 20px;
}

.filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #535353;
}
.filter_by_type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.filter_by_gemstone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.filter_by_gemstone h5 {
  margin-left: 20px;
}
.filter h5 {
  font-weight: 500;
  margin-right: 20px;
  font-size: 16px;
  letter-spacing: 0.02em;
}

.dropdown {
  width: 200px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
}
.dropdown_btn {
  padding: 15px 20px;
  background: #fff;
  -webkit-box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.03);
          box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.03);
  font-weight: bold;
  color: grey;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 4px;
  text-transform: capitalize;
  letter-spacing: 0.02em;
}
.dropdown_content {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  padding: 10px;
  background: rgba(115, 72, 96, 0.8);
  -webkit-box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
          box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  color: #ffffff;
  z-index: 1;
  border-radius: 4px;
}
.dropdown_item {
  padding: 10px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  text-transform: capitalize;
  letter-spacing: 0.08em;
}
.dropdown_item:hover {
  background: rgba(115, 72, 96, 0.8);
  border-radius: 4px;
}
.dropdown_mobile {
  text-transform: uppercase;
}
.dropdown_mobile_sort {
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.dropdown_mobile_sort div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dropdown_mobile_sort div svg {
  height: 20px;
  stroke-width: 0.4px;
}

/* Sidebar filter Menu */
.filteringMenu {
  height: 100%;
  width: calc(100% - 50px);
  position: fixed;
  z-index: 10;
  right: 0;
  top: 0;
  -webkit-animation: slide-open 0.3s forwards;
          animation: slide-open 0.3s forwards;
}
@-webkit-keyframes slide-open {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}
@keyframes slide-open {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}
.filteringMenu_sidebar {
  position: absolute;
  top: 0;
  height: 100%;
  left: 100%;
  width: 100%;
  outline-width: 0;
  background-color: #f2ecef;
  z-index: 10;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  overflow: hidden;
}
.filteringMenu_header {
  color: #535353;
  text-transform: uppercase;
  padding-top: 8px;
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-weight: bold;
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.filteringMenu_button {
  color: #535353;
  width: calc(100% - 40px);
  padding: 9px 10px;
  margin: 16px auto;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#734860), to(#dac6ce));
  background-image: linear-gradient(to top, #734860 0%, #dac6ce 100%);
  font-weight: normal;
  color: #ffffff;
  border: none;
  outline: none;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 13px;
}
.filteringMenu_button_wrapper {
  width: 100%;
  height: 70px;
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
  text-align: center;
  overflow: hidden;
}
.filteringMenu_reset {
  position: absolute;
  right: 10px;
  color: grey;
  text-transform: uppercase;
  font-weight: 200;
}

.menu {
  width: 100%;
  z-index: 100;
  height: 100vh;
}
.menu_item {
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: background var(--speed);
  transition: background var(--speed);
  padding: 0.5rem;
  background: #ffffff;
  border-bottom: 1px solid #EEDFE7;
  color: grey;
}
.menu_item:hover {
  color: #734860;
}
.menu_subitem {
  padding-left: 20px;
  text-transform: capitalize;
}
.menu_subitem_checked {
  color: #535353;
  font-weight: 900;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.menu_subitem_checked span {
  margin-right: 10px;
}

/* Sidebar filter Menu CSSTransition classes */
.menu-primary-enter {
  position: absolute;
  -webkit-transform: translateX(-110%);
          transform: translateX(-110%);
}

.menu-primary-enter-active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  -webkit-transition: all var(--speed) ease;
  transition: all var(--speed) ease;
}

.menu-primary-exit {
  position: absolute;
}

.menu-primary-exit-active {
  -webkit-transform: translateX(-110%);
          transform: translateX(-110%);
  -webkit-transition: all var(--speed) ease;
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  -webkit-transform: translateX(110%);
          transform: translateX(110%);
}

.menu-secondary-enter-active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  -webkit-transition: all var(--speed) ease;
  transition: all var(--speed) ease;
}

.menu-secondary-exit-active {
  -webkit-transform: translateX(110%) ia;
          transform: translateX(110%) ia;
  -webkit-transition: all var(--speed) ease;
  transition: all var(--speed) ease;
}

.admin_form {
  padding-top: 110px;
}
.admin_form h3 {
  text-align: center;
  color: grey;
  text-transform: capitalize;
}
.admin_form h4 {
  color: grey;
  text-transform: capitalize;
  padding-left: 10px;
}
.admin_products h3 {
  text-align: center;
  color: grey;
  text-transform: capitalize;
  padding-top: 30px;
}
.admin_products thead tr th {
  font-weight: bold;
  padding: 10px;
}
.admin_products tbody tr td {
  padding: 10px;
}
.admin_mainInfoWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px dotted grey;
  padding-bottom: 20px;
}
.admin_specsWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px dotted grey;
  padding-bottom: 20px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.admin_photosWrap {
  border-top: 1px dotted grey;
  padding: 20px 0 20px 0;
}
.admin_photosWrap h4 {
  margin-bottom: 0;
}
.admin_photos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.admin_photo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 10px 0 8px;
}
.admin_photo img {
  margin-bottom: 10px;
}
.admin_photo_upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.admin_photo_upload button {
  margin-top: 8px;
}
.admin_specs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.admin_spec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 992px) {
  .basket {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
}
@media (max-width: 768px) {
  .header_menu_link {
    padding: 0 20px;
  }
  .header_menu_item {
    font-size: 14px;
  }
  .header_icons_basket img {
    width: 16px;
  }
  .header_icons_basket svg {
    height: 15px;
    width: 20px;
  }
  .explore {
    min-height: 0;
    margin-bottom: 30px;
  }
  .explore_row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .explore_descr {
    margin-top: 30px;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 1vh;
    margin-top: 1vh;
    font-size: 15px;
  }
  .explore_btn {
    display: block;
    margin: 0 auto;
    width: 200px;
    height: 50px;
  }
  .explore_img {
    display: none;
  }
  .explore_img_mobile {
    display: block;
    width: 100%;
    padding-top: 60px;
  }
  .filter_and_sort {
    display: none;
  }
  .filter_and_sort_header {
    font-size: 28px;
    padding-bottom: 0;
  }
  .filter_and_sort_mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .slider_arrow_left {
    left: 0px;
  }
  .slider_arrow_right {
    right: 0px;
  }
  .shop {
    padding-bottom: 30px;
  }
  .shop_title {
    font-size: 30px;
    text-align: center;
    padding-top: 30px;
  }
  .shop_categories {
    padding-top: 30px;
  }
  .contact {
    margin: 30px 0 0 0;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .contact_img {
    display: none;
  }
  .contact_descr {
    margin: 0 auto;
    text-align: center;
  }
  .contact_descr_title {
    font-size: 25px;
  }
  .contact_descr_text {
    max-width: 100%;
    margin-top: 4px;
    font-size: 14px;
  }
  .contact_form {
    margin-left: 0;
  }
  .contact_form form {
    margin-top: 20px;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .contact_form form input {
    min-height: 50px;
    font-size: 16px;
  }
  .contact_form form input:nth-child(3) {
    height: 50px;
  }
  .contact_form form input:last-child {
    min-height: 0;
    display: block;
    margin: 0 auto;
    width: 200px;
    height: 50px;
  }
  .contact_form_success {
    display: block;
    margin: 0 auto;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .products {
    padding-top: 6vh;
  }
  .products_container {
    margin-top: 30px;
  }
  .product {
    padding-top: 65px;
  }
  .product_nav {
    font-size: 16px;
  }
  .product_img {
    margin: 0 auto;
    margin-top: 30px;
  }
  .product_info {
    margin-top: 80px;
    text-align: center;
  }
  .product_info_divider {
    max-width: 100%;
  }
  .product_info_wrapper {
    max-width: 100%;
  }
  .product_info_specsAndShipping ul {
    margin-left: 15px;
    text-align: left;
  }
  .product_info_descr {
    margin-top: 16px;
    font-size: 15px;
    max-width: 100%;
  }
  .product_info_btn {
    display: block;
    margin: 0 auto;
    width: 200px;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .product_info_btn_qty {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    width: 200px;
    margin-bottom: 20px;
  }
  .product_info_btn_qty button {
    height: 50px;
  }
  .footer_wrapper div {
    font-size: 10px;
  }
  footer .footer_logo img {
    width: 70px;
  }
  .basket {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
  .basket_container {
    padding-top: 90px;
  }
  .basket_payment_header {
    margin-bottom: 20px;
  }
  .basket_item {
    margin-bottom: 20px;
  }
  .dropdown {
    width: 80px;
  }
  .dropdown_content {
    top: 104%;
    left: 0;
    font-size: 10px;
    padding: 3px;
    width: calc(50vw - 10px);
    text-align: center;
    -webkit-animation: dropdown-open 0.6s forwards;
            animation: dropdown-open 0.6s forwards;
  }
  @-webkit-keyframes dropdown-open {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100%;
    }
  }
  @keyframes dropdown-open {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100%;
    }
  }
  .dropdown_item {
    text-transform: capitalize;
    padding: 2px 8px;
  }
  .dropdown_item_selected {
    background-color: rgba(115, 72, 96, 0.8);
    text-transform: capitalize;
    border-radius: 4px;
    margin: 3px;
    padding: 2px;
  }
}/*# sourceMappingURL=App.css.map */