=title
    font-family: Atlas Grotesk
    font-weight: 300
    line-height: 120%

=text
    font-family: Atlas Grotesk
    font-size: 13px
    font-weight: 300
    line-height: 140%
    letter-spacing: 0.01em
    color: $text_color
=btn 
    font-size: 18px
    font-weight: 500
    letter-spacing: 0.05em
    color: $white_color
    // background-color: $accent_color
    background-image: linear-gradient(to top, $button_color_1 0%, $button_color_2 100%)
    border: none  
    border-radius: 4px
    outline: none
    &:focus
        outline: none
=btn_hover
    &:hover
        background-image: none
        background-color: $icons_color
=btn_focus
    &:focus
        outline: none
=footer_text
    font-size: 14px
    font-weight: 300
    line-height: 19px
    letter-spacing: 0.04em
    color: $text_color
=make-container($padding-x: $container-padding-x) 
  width: 100%
  padding-right: $padding-x
  padding-left: $padding-x
  margin-right: auto
  margin-left: auto
