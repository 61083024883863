@font-face
  font-family: 'Atlas Grotesk'
  font-weight: 100
  src: local('AtlasGrotesk-thin'), url('../fonts/ATLASGROTESK-THIN.OTF') format('otf')

@font-face
  font-family: 'Atlas Grotesk'
  font-weight: 300
  src: local('AtlasGrotesk-light'), url('../fonts/ATLASGROTESK-LIGHT.OTF') format('otf')

@font-face
  font-family: 'Atlas Grotesk'
  font-weight: 400
  src: local('AtlasGrotesk-regular'), url('../fonts/ATLASGROTESK-REGULAR.OTF') format('otf')

@font-face
  font-family: 'Atlas Grotesk'
  font-weight: 500
  src: local('AtlasGrotesk-medium'), url('../fonts/ATLASGROTESK-MEDIUM.OTF') format('otf')

@font-face
  font-family: 'Atlas Grotesk'
  font-weight: 700
  src: local('AtlasGrotesk-black'), url('../fonts/ATLASGROTESK-BLACK.OTF') format('otf')