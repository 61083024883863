$text_color: grey
$title_color: #535353
$bg_color: #f2ecef
$input_color: #9D9D9D
$white_color: #ffffff
$accent_color: #734860
$icons_color: #dac6ce
$categories_bg: #f2ecef
$border_color: #DABCC6
$button_color_1: #734860
$button_color_2:  #dac6ce
$background_1: rgba(115,72,96,0.8)
$background_2: rgba(218,198,206,0.8)
$dropdown_1: rgba(115,72,96,0.7)
$dropdown_2: rgba(115,72,96,0.8)
$breakpoint_xxl: 1400px
$breakpoint_xl: 1200px
$breakpoint_lg: 992px
$breakpoint_md: 768px
$breakpoint_sm: 576px
$speed: 500ms



   
